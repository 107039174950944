<template>
    <div class="footer">
        <div class="left">Heritage Property Experts, LLC</div>
        <div class="right">
            <ul>
                <li>
                    <router-link to="/policy">Privacy Policy</router-link>
                </li>
                <li><a href="/#contact">Contact</a></li>
            </ul>
        </div>
    </div>
</template>

<script>
    import { utilMixin } from '@/utilMixin'

    export default {
        name: 'FooterBar',
        mixins: [utilMixin],
    }
</script>
