<template>
    <section-block class="dark-background">
        <div class="row">
            <div class="col-12 col-lg-2"></div>
            <div class="col-12 col-lg-4">
                <h1>Elevate Your Property Management</h1>
                <div class="font-align-left mb-2">Our experienced team provides comprehensive property management solutions to save you time and maximize your investments.</div>
                <a href="/#contact"><button class="button-light mb-2">Get Started</button></a>
            </div>
            <div class="col-12 col-lg-5">
                <img src="../assets/homeKey.jpg" alt="Home Key" class="w-75 rounded" />
            </div>
        </div>
    </section-block>
    <section-block id="services">
        <h1 class="text-center">Our Property Management Services</h1>
        <div class="text-center">We offer a comprehensive suite of property management services to meet the unique needs of our clients.</div>
        <div class="row">
            <div class="col-12 col-lg-2"></div>
            <div class="col-12 col-lg-4">
                <ul>
                    <li>
                        <h4 class="fw-bold">Tenant Screening</h4>
                        <div>We thoroughly vet potential tenants to ensure the right fit for your property.</div>
                    </li>
                    <li>
                        <h4 class="fw-bold">Rent Collection</h4>
                        <div>Our team handles all rent collection and payment processing, ensuring timely payments.</div>
                    </li>
                    <li>
                        <h4 class="fw-bold">Maintenance & Repairs</h4>
                        <div>We coordinate all necessary maintenance and repairs to keep your property in top condition.</div>
                    </li>
                    <li>
                        <h4 class="fw-bold">Financial Reporting</h4>
                        <div>Detailed financial reports and statements to keep you informed about the performance of your property.</div>
                    </li>
                </ul>
            </div>
            <div class="col-12 col-lg-4">
                <img src="../assets/homeInterior.jpg" alt="Home Interior" class="w-75 rounded mt-lg-4" />
            </div>
            <div class="col-12 col-lg-2"></div>
        </div>
    </section-block>
    <hr />
    <section-block id="expertise">
        <div class="row">
            <div class="col-12 col-lg-6">
                <h1>Trusted Property Management Experts</h1>
                <div class="font-align-left mb-2">
                    With years of experience in the industry, our team of property management experts are dedicated to helping you maximize the value of your investments.
                </div>
            </div>
            <div class="col-12 col-lg-3"></div>
            <div class="col-12 col-lg-3 align-content-center">
                <a href="/#contact"> <button class="button-dark">Contact Us</button></a>
            </div>
        </div>
    </section-block>
    <hr />
    <section-block id="contact" class="text-center container">
        <h1>Get in Touch</h1>
        <div class="mb-4">Fill out the form below and one of our property management experts will be in touch with you shortly.</div>
        <form v-if="!submitted" name="contact" action="/#contact" method="POST" netlify data-netlify-honeypot="bot-field">
            <input type="hidden" name="form-name" value="contact" />
            <div class="mb-3 mx-auto w-75">
                <label for="name" class="form-label">Name</label>
                <input type="text" name="name" id="name" class="form-control" v-model="form.name" placeholder="Enter your name" required />
                <div v-if="nameIsInvalid" class="text-danger">Please enter a valid name.</div>
            </div>
            <div class="mb-3 mx-auto w-75">
                <label for="email" class="form-label">Email</label>
                <input type="email" name="email" id="email" class="form-control" v-model="form.email" placeholder="Enter your email" required />
                <div v-if="emailIsInvalid" class="text-danger">Please provide a valid email.</div>
            </div>
            <div class="mb-3 mx-auto w-75">
                <label for="phone" class="form-label">Phone Number</label>
                <input type="tel" name="phone" id="phone" class="form-control" v-model="form.phone" placeholder="Enter your phone number" required />
                <div v-if="phoneIsInvalid" class="text-danger">Please provide your phone number.</div>
            </div>
            <div class="mb-3 mx-auto w-75">
                <label for="message" class="form-label">Message</label>
                <textarea name="message" id="message" class="form-control" v-model="form.message" rows="4" placeholder="Enter your message"></textarea>
                <div v-if="messageIsInvalid" class="text-danger">Please enter your message.</div>
            </div>
            <button type="submit" class="button-dark w-75">Submit</button>
        </form>
        <div v-else class="alert alert-success mt-4 mx-auto w-75" role="alert">Message sent!</div>
    </section-block>
</template>

<script>
    import SectionBlock from '@/components/SectionBlock'
    import { utilMixin } from '@/utilMixin'
    export default {
        name: 'Home',
        components: { SectionBlock },
        mixins: [utilMixin],
        data() {
            return {
                nameIsInvalid: false,
                emailIsInvalid: false,
                phoneIsInvalid: false,
                messageIsInvalid: false,
                form: {
                    name: '',
                    email: '',
                    phone: '',
                    message: '',
                },
                submitted: false,
            }
        },
        methods: {
            handleSubmit() {
                this.clearInvalids()
                const valid = this.validateInputs()
                if (!valid) {
                    console.log('Invalid form')
                    return
                }

                this.submitted = true
                setTimeout(() => {
                    this.submitted = false
                    this.form = {
                        name: '',
                        email: '',
                        phone: '',
                        message: '',
                    }
                }, 3000)
            },
            clearInvalids() {
                this.nameIsInvalid = false
                this.emailIsInvalid = false
                this.phoneIsInvalid = false
                this.messageIsInvalid = false
            },
            validateInputs() {
                this.nameIsInvalid = !this.form.name
                this.emailIsInvalid = !this.form.email
                this.phoneIsInvalid = !this.form.phone
                this.messageIsInvalid = !this.form.message

                return !this.nameIsInvalid && !this.emailIsInvalid && !this.phoneIsInvalid && !this.messageIsInvalid
            },
        },
    }
</script>

<style scoped></style>
