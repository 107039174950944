<template>
    <nav @scroll="onScroll" class="nav drop-shadow p-1" :class="{ 'nav-hidden': !showNav }">
        <div class="left mx-md-6">
            <a href="/">
                <img class="logo" src="../assets/logo.png" />
            </a>
        </div>
        <div class="right">
            <ul class="desktop">
                <li><a href="/#services">Services</a></li>
                <li><a href="/#expertise">Expertise</a></li>
                <li><a href="/#contact">Contact</a></li>
            </ul>
            <hamburger class="d-block d-sm-none" :is-expanded="isExpanded" @click="toggleMenu" />
            <div class="component-navbar d-block d-sm-none" :class="{ expanded: isExpanded }">
                <div class="mobile-nav">
                    <ul>
                        <li>
                            <a href="/#services" class="nav-item" @click="toggleMenu">Services</a>
                        </li>
                        <li>
                            <a href="/#expertise" class="nav-item" @click="toggleMenu">Expertise</a>
                        </li>
                        <li>
                            <a href="/#contact" class="nav-item" @click="toggleMenu">Contact</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
    import Hamburger from '@/components/Hamburger'
    import { utilMixin } from '@/utilMixin'
    export default {
        name: 'NavigationBar',
        components: {
            Hamburger,
        },
        mixins: [utilMixin],
        mounted() {
            this.addScrollListener()
        },
        beforeUnmount() {
            this.removeScrollListener()
        },
        data() {
            return {
                showNav: true,
                lastScrollPosition: 0,
                isExpanded: false,
            }
        },
        methods: {
            addScrollListener() {
                window.addEventListener('scroll', this.onScroll)
            },
            removeScrollListener() {
                window.removeEventListener('scroll', this.onScroll)
            },
            onScroll() {
                const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
                if (currentScrollPosition < 0) {
                    return
                }
                // Stop executing this function if the difference between
                // current scroll position and last scroll position is less than some offset
                if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 60) {
                    return
                }
                this.showNav = currentScrollPosition < this.lastScrollPosition
                this.lastScrollPosition = currentScrollPosition
            },
            toggleMenu() {
                if (this.isExpanded) {
                    this.addScrollListener()
                    this.scrollToTop()
                } else {
                    this.removeScrollListener()
                }
                this.isExpanded = !this.isExpanded
                this.onScroll()
            },
        },
    }
</script>
