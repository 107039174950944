import { createWebHistory, createRouter } from 'vue-router'
import Home from '@/pages/Home.vue'
import PrivacyPolicy from '@/pages/PrivacyPolicy'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: '/policy',
        name: 'PrivacyPolicy',
        component: PrivacyPolicy,
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
})

export default router
